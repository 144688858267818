/* src/styles/WorkspaceSources.css */

.workspace-sources-container {
    padding: 20px;
  }
  
  .integration-header {
    border-top: 3px solid #4CAF50;
  }
  
  .integration-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 10px;
    display: inline-block;
  }
  
  .integration-name {
    font-weight: bold;
    font-size: 1.1em;
    vertical-align: middle;
  }
  
  .integration-description {
    color: #666;
    font-size: 0.9em;
    margin-left: 34px;
    display: block;
    margin-top: 4px;
  }
  
  .primaryBtn.btn-add,
  .primaryBtn.btn-configure,
  .primaryBtn.btn-toggle,
  .primaryBtn.btn-delete {
    padding: var(--spacing-sm) !important;
    font-size: var(--font-size-small) !important;
    margin-right: var(--spacing-sm) !important;
    min-height: auto;
  }

  .primaryBtn.btn-add {
    background-color: #4CAF50 !important;
  }
  
  .primaryBtn.btn-delete {
    background-color: var(--bad-color) !important;
  }

  .btn-toggle.enabled {
    background-color: #f44336;
  }
  
  .btn-toggle.disabled {
    background-color: #4CAF50;
  }
  
  .btn-toggle:hover {
    opacity: 0.9;
  }