/* src/styles/Sidebar.css */

/* Sidebar Styles */
.sidebar {
    width: var(--sidebar-width);                           /* Global sidebar width */
    background-color: var(--secondary-bg-color);           /* Use global secondary background color */
    padding-top: var(--spacing-xsm);                        /* Consistent spacing from the theme */
    position: fixed;
    top: calc(var(--header-height) + 20px);
    bottom: 0;
    left: 0;
    z-index: 5;
    transition: width 0.3s ease;                           /* Smooth transition for sidebar width */
}

.workspace-name-heading {
    color: var(--secondary-color);
    margin-top: var(--spacing-sm);
    text-align: center;
    font-size: small;
}

.workspace-name {
    color: var(--text-color);
    margin-bottom: var(--spacing-md);
    margin-left: var(--spacing-sm);
    margin-right: var(--spacing-sm);
    margin-top: var(--spacing-xsm);
    text-align: center;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: help;
    max-height: 3em;
    white-space: pre-wrap;
    word-break: normal;
}

/* Sidebar toggle button (SVG icon) */
.sidebar-toggle {
    padding: var(--spacing-sm);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;  /* Animation on toggle click */
    color: var(--text-color);         /* Ensure text color is set */
}

.sidebar-toggle svg {
    width: 16px;                       /* Set a visible width */
    height: 16px;                      /* Set a visible height */
    fill: var(--text-color);           /* Ensure the fill color is set */
}

/* Sidebar content */
.sidebar-content {
    transition: opacity 0.3s ease, transform 0.3s ease;    /* Smooth transition for opacity and movement */
}

.sidebar-content.hidden {
    opacity: 0;
    pointer-events: none;                                  /* Disable interactions when hidden */
    transform: translateX(-100%);                          /* Move the content out of view when hidden */
}

.sidebar .MuiListItemButton-root {
    color: var(--text-color);                              /* Use global text color */
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    background-color: transparent;
    padding: var(--spacing-sm) var(--spacing-sm);          /* Consistent padding from the theme */
    text-align: center; 
    transition: color 0.1s ease;                           /* Smooth transition for color */
}

.sidebar .MuiSvgIcon-root {
    color: var(--text-color);                              /* Use global text color for icons */
    transition: transform 0.3s ease, color 0.1s ease;      /* Smooth transition for icon color and size */
}

.sidebar.collapsed .MuiSvgIcon-root {
    transform: scale(0.8);                                 /* Slightly reduce icon size when sidebar is collapsed */
}

/* Hover effects */
.sidebar .MuiListItemButton-root:hover {
    background-color: var(--secondary-color-dark);         /* Use darker secondary color for hover */
    color: var(--primary-color);                           /* Use global primary color for text on hover */
}

.sidebar .MuiListItemButton-root:hover .MuiSvgIcon-root,
.sidebar .MuiListItemButton-root:hover {
    color: var(--primary-color);                           /* Primary color for icons and text on hover */
}

/* Sidebar bottom icon */
.sidebar-bottom-icon {
    text-align: center; 
    padding: var(--spacing-lg) 0;                          /* Use theme spacing */
}

.sidebar-bottom-icon img {
    width: 50px;
    height: 50px;
    transition: opacity 0.3s ease;                         /* Smooth transition for bottom icon */
}

.sidebar.collapsed .sidebar-bottom-icon img {
    opacity: 0;                                            /* Hide bottom icon when collapsed */
}

/* Active effects */
.sidebar .MuiListItemButton-root:active {
    background-color: var(--secondary-color-dark);         /* Use darker secondary color for active state */
}

.sidebar .MuiListItemButton-root:active .MuiSvgIcon-root,
.sidebar .MuiListItemButton-root:active {
    color: var(--primary-color);                           /* Primary color for active state */
}

/* Active tab and icon styles */
.activeTab {
    background-color: var(--variant-plainHoverBg, var(--secondary-bg-color)); /* Use global background color */
}

.activeIcon {
    color: var(--primary-color) !important;                /* Primary color for active icon */
}

.sidebar-link {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm);
    text-decoration: none;
    width: 100%;
    color: inherit;  /* This will inherit the text color from the parent */
}

.sidebar-link:hover {
    background-color: var(--secondary-bg-color);
}

/* Keep the active state styling if you had it */
.activeTab .sidebar-link {
    color: var(--primary-color);
}