/* src/styles/MetalmindTheme.css */

/* 
  This file contains the global theme for MetalMind projects.
  It includes color, spacing, and layout variables with support for light and dark mode, 
  and responsive breakpoints for different screen sizes.

  TODO: Consider maming the selection of theme dynamic to allow for white labeling.
*/

:root {
    /* ------------------------- */
    /*    Responsive Breakpoints  */
    /* ------------------------- */
    --breakpoint-mobile: 480px;
    --breakpoint-tablet: 768px;
    --breakpoint-desktop: 1024px;
    --breakpoint-large-desktop: 1440px;
  
    /* -------------------------- */
    /*    Color Palette (Default Light Mode)    */
    /* -------------------------- */
    --primary-color: #FF6F00;
    --secondary-color: #7f7f7f;
    --primary-bg-color: #ffffff;
    --secondary-bg-color: #E0E0E0;
    --text-color: #080505;
    --text-color-light: #ffffff;
    --focus-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    
    /* Global colors */
    --good-color: #28a745;
    --bad-color: #dc3545;
    --warning-color: #ffc107;
  
    /* ------------------------- */
    /*        Typography          */
    --font-family-body: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    --font-size-base: 16px;
    --font-size-small: 12px;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 16px;
    --font-weight-bold: 600;
    --font-smoothing-webkit: antialiased;
    --font-smoothing-moz: grayscale;
  
    /* ------------------------- */
    /*         Spacing            */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;
  
    /* ------------------------- */
    /*   Border Radius Values     */
    --border-radius-small: 4px;      /* Small elements like buttons */
    --border-radius-medium: 8px;     /* For medium elements */
    --border-radius-large: 12px;     /* Larger elements or containers */
    --border-radius-full: 50%;       /* For fully rounded elements (e.g., circular avatars) */
  
    /* ------------------------- */
    /*      Layout Dimensions     */
    --form-width: 400px;
    --sidebar-width: 0px; /* Set programmatically in WorkspaceSidebar.tsx */
    --header-height: 60px;
    --table-row-height: 100px;
  
    /* Responsive container widths */
    --container-width-mobile: 100%;
    --container-width-tablet: 85%;
    --container-width-desktop: 75%;
    --container-width-large-desktop: 65%;
  }
  
  /* Dark Theme */
  [data-theme="dark"] {
    --primary-color: #FFAB40;
    --secondary-color: #9E9E9E;
    --primary-bg-color: #121212;
    --secondary-bg-rgb-values: 66, 66, 66;
    --secondary-bg-color: rgb(var(--secondary-bg-rgb-values));
    --text-color: #ffffff;
    /* --focus-shadow: 0 2px 6px rgba(255, 255, 255, 0.075); */
  }
  
  /* Light Theme */
  [data-theme="light"] {
    --primary-color: #FF8F20;
    --secondary-color: #7f7f7f;
    --primary-bg-color: #ffffff;
    --secondary-bg-rgb-values: 224, 224, 224;
    --secondary-bg-color: rgb(var(--secondary-bg-rgb-values));
    --text-color: #080505;
    --focus-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* -------------------------- */
  /*     Media Queries for       */
  /*    Responsive Breakpoints   */
  /* -------------------------- */
  
  @media (max-width: var(--breakpoint-mobile)) {
    .sidebar {
      width: 100%;
      padding-top: var(--spacing-md);
    }
  
    .formContainer {
      width: var(--container-width-mobile);
    }
  }
  
  @media (min-width: var(--breakpoint-tablet)) {
    .formContainer {
      width: var(--container-width-tablet);
    }
  }
  
  @media (min-width: var(--breakpoint-desktop)) {
    .formContainer {
      width: var(--container-width-desktop);
    }
  }
  
  @media (min-width: var(--breakpoint-large-desktop)) {
    .formContainer {
      width: var(--container-width-large-desktop);
    }
  }