/* src/styles/UserWorkspaces.css */

/* Specific container for header */
.headerContainer {
    display: flex;
    justify-content: space-between;
}

/* Workspace Name */
.workspaceName {
    color: var(--primary-color);                   /* Primary color for workspace names */
    text-decoration: underline;
    font-weight: var(--font-weight-bold);          /* Use global bold font weight */
}

.subName {
    font-weight: var(--font-weight-bold);          /* Use global bold font weight */
}

.checkboxHead {
    max-width: 1% !important;
}

.optionsIcon {
    cursor: pointer;
}

.btnCont {
    display: flex;
    justify-content: space-between;
}

.editIcon {
    cursor: pointer;
}

.editIconSvg, .trashIconSvg {
    margin-bottom: -5px !important;
    margin-right: var(--spacing-xs) !important;
}

.editIconSvg path {
    fill: var(--primary-color);                     /* Primary color for edit icons */
}

.editBtnCont {
    max-width: 20px;
}

.MuiButton-root.iconButton {
    min-width: auto !important;
    max-width: 20px !important;
    padding: 0px !important;
}

.leaveWSCont {
    cursor: pointer;
}

.customSpinner {
    --CircularProgress-progressColor: var(--primary-color) !important; /* Progress slice - primary color */
    color: white !important; /* Spinner track - white */
  }