.userContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-lg); /* Use large spacing */
  max-width: 80%;
  margin-left: auto;
}

.assistantContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-lg); /* Use large spacing */
  max-width: 80%;
  margin-right: auto;
}

.userMessage {
  background: var(--secondary-bg-color); /* Consistent secondary background */
  padding: var(--spacing-lg); /* Use large padding */
  border-radius: var(--border-radius-medium); /* Medium border radius */
  box-shadow: var(--focus-shadow); /* Use global shadow */
  outline: transparent solid 1px;
}

.assistantMessage {
  background: var(--primary-bg-color); /* Primary background color */
  padding: var(--spacing-lg); /* Large padding */
  border-radius: var(--border-radius-medium); /* Medium border radius */
  box-shadow: var(--focus-shadow); /* Global shadow */
  outline: transparent solid 1px;
  line-height: 24px;
}

.backgroundAssistantMessage {
  background: var(--primary-bg-color); /* Primary background */
  padding: var(--spacing-md); /* Medium padding */
  border-radius: var(--border-radius-medium); /* Medium border radius */
  box-shadow: var(--focus-shadow); /* Global shadow */
  outline: transparent solid 1px;
  line-height: 20px;
  font-size: smaller;
}

.citation {
  font-size: smaller;
  font-weight: var(--font-weight-bold); /* Bold font weight */
  text-align: center;
  border-radius: var(--border-radius-small); /* Small border radius */
  padding: 0 2; /* Small padding */
  background: var(--secondary-bg-color); /* Use secondary background */
  color: var(--primary-color); /* Primary color for text */
  text-decoration: none;
  cursor: pointer;
}

.citation:hover {
  text-decoration: underline;
}

.foldButtonContainer {
  display: flex;
  width: 100%;
}

.foldButtonContainer:hover {
  text-decoration: none;
}

.foldButton {
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-transform: none;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.foldButtonText:hover {
  text-decoration: underline;
}

.arrowClosed, .arrowOpen {
  font-size: xx-small;
  margin-right: var(--spacing-xs); /* Extra small margin */
  margin-bottom: 1px;
  transition: transform 0.3s;
  line-height: 16px;
}

.arrowOpen {
  transform: rotate(-90deg);
}