/* src/styles/WorkspaceSubscription.css */

/* Main container for the subscription management view */
.subscription-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-lg);
    margin: 0 auto;
    max-width: var(--container-width-desktop); /* Ensure a maximum width */
  }
  
  /* Inner content container */
  .subscription-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg); /* Space between the sections */
  }
  
  /* Title styling for the page */
  .subscription-page .title {
    font-size: var(--font-size-h1);
    color: var(--primary-color);
    text-align: center;
    margin-bottom: var(--spacing-lg);
  }

  /* Styling for the component cards */
  .card {
    background-color: rgba(var(--secondary-bg-rgb-values), 0.3);
    border-radius: var(--border-radius-large);
    box-shadow: var(--focus-shadow);
    margin-bottom: var(--spacing-lg);
    padding: var(--spacing-lg);
    max-width: var(--container-width-desktop); /* Ensures consistent width */
    width: 100%; /* Makes it responsive */
    margin: 0 auto var(--spacing-lg); /* Centers the card */
  }

  .card h2 {
    color: var(--primary-color);  /* Uses primary color from the global theme */
    font-size: var(--font-size-h2);  /* Defined in the theme for h2 elements */
  }