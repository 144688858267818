/* Base Badge Styles */
.status-badge {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    border-radius: var(--border-radius-medium);
    font-size: 14px;
    color: var(--text-color-light);
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    min-width: 80px;
    white-space: nowrap;
  }
  
  /* Different Status Colors */
  .active-badge {
    background-color: var(--good-color); /* Green */
  }
  
  .inactive-badge {
    background-color: var(--secondary-color-light); /* Gray */
  }
  
  .alert-badge {
    background-color: var(--bad-color); /* Red */
  }
  
  .pending-badge {
    background-color: var(--primary-color); /* Orange */
    cursor: pointer;  
}