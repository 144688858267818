/* Resource Bar Styles */
.resource-bar {
    background: var(--secondary-bg-color);
    border-radius: var(--border-radius-medium);
    height: 16px;
    margin: var(--spacing-sm) 0;
    overflow: hidden;
}

/* Progress bar */
.resource-progress {
    height: 100%;
    border-radius: var(--border-radius-medium);
    transition: width 0.3s ease;
}

.resource-progress.good {
    background: var(--good-color); /* Global good color */
}

.resource-progress.warning {
    background: var(--warning-color); /* Add this to your theme or color palette */
}

.resource-progress.danger {
    background: var(--bad-color); /* Global danger color */
}

div.card-split {
    display: flex !important;
    justify-content: space-between !important;
}

div.card-content {
    width: 60%;
    padding-right: var(--spacing-sm);
}

div.card-side-content {
    width: 40%;
    padding: 'var(--spacing-sm)';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    text-align: center;
}

div.message {
    font-size: small;
}