/* src/styles/MainContent.css */

.main-content {
  margin-left: var(--sidebar-width);
  margin-top: calc(var(--header-height) + 20px);
  display: flex;
  flex: 1;
  transition: margin 0.3s ease;
  padding: 20px;
}

.main-content-no-sidebar {
  margin-top: calc(var(--header-height) + 20px);
  display: flex;
  flex: 1;
  padding: 20px;
}