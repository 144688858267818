div.pricing-container {
  width: 90%;
  padding: 20px;
  padding-top: 0;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 0 5px 15px white;
}

stripe-pricing-table {
  width: 100%; /* Fill the parent container */
}