.questionInputContainer {
    border-radius: var(--border-radius-medium); /* Use medium border radius */
    box-shadow: var(--focus-shadow); /* Use global shadow */
    width: 100%;
    padding: var(--spacing-lg); /* Use large spacing */
    background: var(--secondary-bg-color); /* Use primary background color */
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
}

.questionInputTextArea {
    width: 100%;
    line-height: 20px;
    overflow: hidden;
    flex-grow: 1;
    background-color: var(--secondary-bg-color); /* Ensure consistency */
    color: var(--text-color); /* Text color according to the theme */
    font-family: var(--font-family-body); /* Use body font family */
    font-size: var(--font-size-base);
}

.questionInputTextArea:focus {
    outline: none;
    box-shadow: none;
}

.questionInputButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: var(--spacing-sm); /* Small spacing */
    align-self: flex-end;
}

.questionInputSendButton svg {
    cursor: pointer;
    color: var(--primary-color); /* Text color when active */
}

.questionInputSendButtonDisabled {
    opacity: 0.4;
    cursor: not-allowed;
}