/* src/index.css */

/* 
  Main entry for global styles. This file pulls in the theme and common styles for all components. 
*/

@import 'styles/MetalmindTheme.css';

/* For the whole app */
html {
  overflow-y: scroll; /* This makes the vertical scrollbar always visible */
}

/* Override specific Joy UI table variables */
:root {
  --TableCell-paddingY: var(--spacing-md); /* Override padding for Joy UI */
  --TableCell-paddingX: var(--spacing-md);
  --TableCell-borderColor: var(--secondary-bg-color);
  --TableCell-dataBackground: var(--primary-bg-color); /* Make sure this adapts to dark mode */
}

/* Base styles */
body {
  margin: 0;
  font-family: var(--font-family-body);
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}

/* Layout Container for Sidebar + Content */
.layout-container {
  padding-top: 20px;
  display: flex;
  height: 100%;
  width: 100%;
}

.content-area {
  flex: 1;
  padding: 20px;
  margin-top: calc(var(--header-height + 20px)); /* Push the content down by the height of the upper bar */
  height: 100%;
}

code {
  font-family: var(--font-family-code);
}

/* Layout helpers */
.container {
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global link styles */
a, a:link, a:visited, a:focus, a:hover, a:active {
  color: var(--primary-color);   /* Apply the primary color globally */
  text-decoration: none;         /* Disable underline unless explicitly needed */
}

/* Buttons */
.primaryBtn, .MuiButton-colorPrimary.MuiButton-variantSolid,
.secondaryBtn, .MuiButton-colorNeutral.MuiButton-variantSolid {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none;
  padding: 8px 16px !important; /* Ensure the button has proper padding */
  border-radius: var(--border-radius-medium) !important;
  font-size: 16px;  /* Define a consistent font size */
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 100px;
}

.secondaryBtn, .MuiButton-colorNeutral.MuiButton-variantSolid {
  background-color: var(--secondary-color) !important;
}

.primaryBtn:hover, .MuiButton-colorPrimary.MuiButton-variantSolid:hover,
.secondaryBtn:hover, .MuiButton-colorNeutral.MuiButton-variantSolid:hover {
  opacity: 0.8;
}

.disabledBtn.MuiButton-root {
  background-color: var(--secondary-color) !important;
  color: var(--text-color-light) !important; /* Use lighter text color */
  cursor: not-allowed;
  opacity: 0.5; /* Make the button visually appear disabled */
}

.MuiButton-root.iconButton {
  background: none !important;
  border: none;
  padding: 0;
  margin-bottom: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color) !important; /* Inherit color so it blends in with the surrounding icons */
}

.iconButton:disabled {
  cursor: not-allowed; /* Change the cursor when disabled */
  opacity: 0.6; /* Make disabled button slightly transparent */
}

/* Forms */
.formContainer {
  max-width: var(--form-width);
  margin: var(--spacing-lg) auto;
  padding: var(--spacing-lg);
  box-shadow: var(--focus-shadow);
  border-radius: var(--border-radius-large);
}

.MuiInput-root.inputField {
  background-color: var(--primary-bg-color);
}

.inputField input, .MuiInputBase-root input {
  border-radius: var(--border-radius-small) !important;
  background-color: var(--primary-bg-color);
  color: var(--text-color);
  padding: 0px;
}

.MuiSelect-root.selectButton {
  background-color: var(--primary-bg-color);
}

.MuiSelect-root.selectButton:hover {
  background-color: var(--primary-bg-color);
}

.MuiSelect-root.selectButton button{
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}

.MuiSelect-listbox.Mui-expanded {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}

.MuiFormLabel-root.formLabel {
  color: var(--text-color);
}

/* General select box styling to match MuiSelect-root */
select {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
  border: 1px solid var(--secondary-color);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-base);
  transition: border-color 0.3s ease;
}

select:focus {
  outline: none;
  border-color: var(--primary-color);
}

select:hover {
  border-color: var(--primary-color);
}

/* Ensure consistent padding and size for dropdown items */
select option {
  padding: var(--spacing-sm);
  background-color: var(--primary-bg-color);
  color: var(--text-color);
}

/* Modal styles for light and dark mode with Joy UI overrides */
.modal.MuiModalDialog-root {
  background-color: var(--primary-bg-color);
  box-shadow: var(--focus-shadow);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-lg);
  border-color: var(--primary-color);
}

h2.MuiDialogTitle-root {
  color: var(--text-color);
}

h2 {
  font-size: var(--font-size-h2);
  color: var(--primary-color);
}

h3 {
  font-size: var(--font-size-h3);
  color: var(--text-color);
}

p {
  font-size: var(--font-size-base);
  color: var(--text-color);
}

div.page-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-lg);
  margin: 0 auto;
  width: 100%;
}

h1.page-title {
  font-size: var(--font-size-h1);
  color: var(--primary-color);
  text-align: center;
  margin-bottom: var(--spacing-lg);
  font-weight: var(--font-weight-bold);
}

.MuiBox-root.content-box {
  background-color: rgba(var(--secondary-bg-rgb-values), 0.3);
  border-radius: var(--border-radius-large);
  box-shadow: var(--focus-shadow);
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md)
}

/* Context menu */
.MuiBox-root:has(> .contextMenu) {
  padding: 0px;
  box-shadow: var(--focus-shadow);
}

.MuiBox-root.contextMenu {
  background-color: var(--primary-bg-color);
  color: var(--text-color);
  padding: 0px;
}

.MuiBox-root.contextMenuOption {
  padding: 4px;
}

.MuiBox-root.contextMenuOption:hover {
  background-color: var(--secondary-bg-color);
}

/* Table styles for light and dark mode with Joy UI overrides */
.tableBox.MuiBox-root {
  background-color: var(--primary-bg-color);
  padding: var(--spacing-lg);
  box-shadow: var(--focus-shadow);
  border-radius: var(--border-radius-large);
}

.table, .MuiTable-root, .css-an3oje-JoyTable-root {
  width: 100%;
  margin: var(--spacing-lg) 0;
  background-color: var(--primary-bg-color) !important;
  color: var(--text-color) !important;
  table-layout: auto;
}

/* Alternating row styles */
.table tr:nth-child(even) td, 
.MuiTable-root tr:nth-child(even) td, 
.css-an3oje-JoyTable-root tr:nth-child(even) td {
  background-color: rgba(var(--secondary-bg-rgb-values), 0.5) !important; /* Set a lighter or darker color for even rows */
}

.table tr:nth-child(odd) td, 
.MuiTable-root tr:nth-child(odd) td, 
.css-an3oje-JoyTable-root tr:nth-child(odd) td {
  background-color: var(--primary-bg-color) !important;
}

/* Table header and cell styling */
.table th, .table td, 
.MuiTable-root th, .MuiTable-root td, 
.css-an3oje-JoyTable-root th, .css-an3oje-JoyTable-root td {
  padding: var(--spacing-xs) !important;
  padding-left: var(--spacing-sm) !important;
  border: 1px solid var(--secondary-bg-color) !important;
  text-align: left;
  background-color: var(--primary-bg-color) !important;
  color: var(--text-color) !important;
  font-weight: var(--font-weight-bold) !important; 
}

.css-an3oje-JoyTable-root td {
  white-space: nowrap;
  overflow: hidden;
  min-height: 100%;
}

thead th:first-child {
  border-top-left-radius: var(--border-radius-medium) !important;  /* Top left rounded corner */
}

thead th:last-child {
  border-top-right-radius: var(--border-radius-medium) !important;  /* Top right rounded corner */
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius-medium);
}

tbody tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius-medium);
}

/* Joy sheets */
.MuiSheet-root.dataEntryBG {
  background-color: var(--secondary-bg-color);
  border-radius: var(--border-radius-small);
}

/* Miscellaneous */
.boxShadow {
  box-shadow: var(--focus-shadow);
}

.title {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h1);
}

/* Apply color to all IconButtons */
button.MuiIconButton-root {
  color: var(--text-color) !important;
  background-color: transparent !important;
  transition: color 0.125s ease;
}

/* Apply hover color to all IconButtons */
button.MuiIconButton-root:hover {
  color: var(--primary-color) !important;
  background-color: transparent !important;
  transition: color 0.125s ease;
}

/* A centered page status message */
.page-status {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: calc(50% + 0.5 * (var(--header-height) + 20px));
  left: calc(50% + 0.5 * var(--sidebar-width));
  transform: translate(-50%, -50%);
  align-items: center;
  transition: left 0.3s ease;
}
.page-status-icon {
  color: var(--primary-color) !important;
  font-size: 40px !important;
}
.page-status-text {
  color: var(--secondary-color) !important;
  margin-top: 20px !important;
}