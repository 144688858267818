.chatRoot {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 120px);
    overflow: hidden;
}

.chatContainer {
    /* Ensure this takes full height minus space for the chat input */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - var(--sidebar-width) - var(--spacing-lg) - 10); /* Account for sidebar and margin */
    overflow-y: auto; /* Ensure only vertical scroll */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    transition: width 0.3s ease;
}

.applicationTitle {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-lg);
}

.loadingState {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
}

.chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--spacing-xxl);
    height: calc(100vh - var(--spacing-xxl) * 5);
}

.chatEmptyStateTitle {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-bold);
}

.chatMessageStream {
    flex-grow: 1;
    max-width: 1028px;
    width: 100%;
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding-bottom: var(--spacing-lg); /* Ensure spacing between last message and input */
}

.chatInput {
    position: sticky;
    bottom: 0;
    right: 0;
    width: calc(100vw - var(--sidebar-width) - var(--spacing-lg) - 50px); /* Ensure full width */
    transition: width 0.3s ease;
    padding: var(--spacing-md);
    background-color: var(--primary-bg-color);
    z-index: 10;
}

.chatRow {
    flex: 0 0 var(--table-row-height);
    width: 100%;
    display: flex;
}

.loadingLogo {
    font-size: var(--font-size-h2);
}

.commandsContainer {
    display: flex;
    align-self: flex-end;
}

.commandButton {
    margin-bottom: var(--spacing-lg);
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: var(--primary-bg-color);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-medium);
    position: relative;
    box-shadow: var(--focus-shadow);
}

.close-button {
    position: absolute;
    right: var(--spacing-sm);
    top: var(--spacing-sm);
    border: none;
    background: none;
    font-size: var(--font-size-base);
    cursor: pointer;
}

.filterContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.filterElement {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-sm);
}

.filterContainer .chatSettingsSeparator {
    margin-right: var(--spacing-xs);
}

.listTypeCont {
    margin: var(--spacing-lg) var(--spacing-xl);
}

.checkboxCont {
    margin-right: calc(var(--spacing-xxl) * 4);
}

@media only screen and (max-width: var(--breakpoint-mobile)) {
    .chatEmptyStateTitle {
        font-size: var(--font-size-h2);
        font-weight: var(--font-weight-bold);
        margin-top: var(--spacing-md);
        margin-bottom: 0;
    }

    .chatMessageGpt {
        min-width: 300px;
    }

    .chatAnalysisPanel {
        display: none;
    }

    .checkboxCont {
        margin-right: var(--spacing-lg);
    }

    .listTypeCont {
        margin: var(--spacing-lg) var(--spacing-md);
    }
}