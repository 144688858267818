/* src/styles/Upperbar.css */

.upper-bar {
    background-color: var(--secondary-bg-color);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: fixed; 
    top: 0; 
    left: 0;
    right: 0;
    z-index: 2;
    height: var(--header-height); /* Consistent height for the upper bar */
}

.upper-bar-menu {
    display: flex;
    flex: 1;
    justify-content: right;
}

.upper-bar-logo {
    height: 20px;
    width: auto;
}

.theme-switcher {
    margin-right: 100px;
    padding-right: 0px;
}