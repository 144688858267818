/* Modal container */
.modal {
    max-height: 75vh;
    overflow-y: auto;
    padding-bottom: var(--spacing-xl);
    display: flex;
    flex-direction: column;
}

/* Modal content (scrollable area) */
.modal-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: var(--spacing-md);
    max-width: 275px;
}

/* Modal action buttons (Cancel/Save) */
.modal-actions {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--spacing-md);
    position: sticky;
    bottom: 0;
    background-color: var(--primary-bg-color);
}

/* Scrollbar Styling for WebKit Browsers */
.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-track {
    background: var(--secondary-bg-color);
    border-radius: var(--border-radius-medium);
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: var(--border-radius-medium);
    border: 2px solid var(--secondary-bg-color);
}

/* Form Control styling */
.formControl {
    margin-bottom: var(--spacing-sm);
    position: relative;
}

/* Error message styling */
.error-message {
    color: var(--bad-color);
    font-size: var(--font-size-base);
    margin-top: var(--spacing-sm);
    word-wrap: break-word;  /* Allow the text to wrap */
    white-space: normal;    /* Ensure the text doesn't stay on one line */
}

/* Adjust input field when error is present */
.input-error.MuiInput-root {
    border: 2px solid var(--bad-color);
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
    margin-top: 10px;
}

/* Adjusted error bubble styling */
.error-bubble {
    position: absolute;
    top: 0px;
    right: 5px;
    background-color: var(--bad-color);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}

.error-bubble::after {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid var(--bad-color);
}

/* Container for each configuration (ConfigBlock) */
.config-block {
    padding: var(--spacing-md);
    border: 1px solid var(--secondary-color);
    border-radius: var(--border-radius-medium);
    background-color: var(--secondary-bg-color);
    position: relative;
}

/* ConfigBlock delete button */
.config-block .MuiButton-root.delete-button {
    position: absolute;
    top: var(--spacing-xs); /* Adjusted top spacing */
    right: var(--spacing-xs); /* Adjusted right spacing */
    background-color: var(--bad-color) !important;
    color: var(--text-color-light) !important;
    border-radius: var(--border-radius-small) !important;
    padding: var(--spacing-xs) !important; /* Ensure no extra padding */
    font-size: 12px; /* Keep small font */
    min-width: auto !important; /* Remove any default min-width */
    min-height: auto !important; /* Adjust height to content */
}

.delete-button .icon {
    margin-right: var(--spacing-xs);
    font-size: 18px;
}

/* Add Configuration button - styled similarly to delete button (ConfigBlock) */
.add-configuration .MuiButton-root.add-config-btn {
    background-color: var(--good-color) !important;
    color: var(--text-color-light) !important;
    padding: var(--spacing-xs) var(--spacing-sm) !important;
    border-radius: var(--border-radius-small) !important;
    box-shadow: var(--focus-shadow) !important;
    font-size: 12px !important;
}

.add-configuration .MuiButton-root.add-config-btn:hover {
    opacity: 0.9 !important;
}

.add-configuration .icon {
    margin-right: var(--spacing-xs);
}

/* Subfield container (FieldRenderer) */
.subfield-container {
    margin-bottom: 10px;
    padding: var(--spacing-sm);
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius-medium);
    background-color: var(--light-bg-color);
    position: relative;
}

/* Subfield remove button styling (FieldRenderer) */
.MuiButton-root.subfield-remove-button {
    top: 0px;
    right: 5px;
    position: absolute;
    background-color: transparent;
    color: var(--bad-color) !important;
    padding: 0px !important;
    border: none !important;
    transition: color 0.2s ease;
}

.MuiButton-root.subfield-remove-button:hover {
    color: white !important;
    background-color: transparent;
}

.subfield-remove-button .icon {
    font-size: 20px;
}

/* Wrap input and button in a flexbox container */
.plain-array-item {
    display: flex;
    align-items: center; /* Align input and button vertically */
    margin-bottom: 10px; /* Add spacing between array items */
}

/* Ensure the input field takes up the available space */
.plain-array-item input {
    flex-grow: 1; /* Allow input to grow */
    margin-right: var(--spacing-xs); /* Add space between input and button */
}

/* Adjust the remove button positioning to align with the input */
.array.MuiButton-root.subfield-remove-button {
    top: auto; /* Remove absolute positioning */
    right: auto; /* Remove absolute positioning */
    position: relative; /* Make the button position relative */
    margin-left: var(--spacing-xs); /* Space between input and button */
    padding: 0px !important;
    border: none !important;
}


/* Subfield add button styling (FieldRenderer) */
.MuiButton-root.subfield-add-button {
    background-color: var(--good-color) !important;
    color: var(--text-color-light) !important;
    padding: var(--spacing-xs) !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    margin-top: 10px !important;
    min-width: auto !important; /* Remove any default min-width */
    min-height: auto !important; /* Adjust height to content */
}

.subfield-add-button .icon {
    margin-right: 4px;
    font-size: 18px;
}